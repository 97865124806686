import React, { useEffect, useRef, useState } from 'react';
import { ISingleSliderHeroProps } from './types';
import useDeviceType from '@/hooks/useDeviceType';
import { path, useApp } from '@wap-client/core';
import Icon from '@/components/base/icon';
import Image from '@/components/base/image';
import SocialIconList from '../social-icon-list';
import Anchor from '@/components/base/anchor';

const SingleSliderHero: React.FC<ISingleSliderHeroProps> = ({
  media,
  emergencyLink,
  isEmergency = false,
}) => {
  const [videoIsPlay, setVideoIsPlay] = useState<boolean>(true);
  const deviceType = useDeviceType();
  const app = useApp();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleVideoToggle = () => {
    setVideoIsPlay((prev) => !prev);
  };

  if (!media) {
    return null;
  }

  useEffect(() => {
    if (videoRef.current) {
      if (videoIsPlay) {
        videoRef.current.play().catch((error) => {
          console.error('Video cannot be played:', error.message);
        });
      } else videoRef.current.pause();
    }
  }, [videoIsPlay]);

  const renderMedia = () => {
    const video =
      media?.[deviceType === 'mobile' ? 'videoMobile' : 'video'] ||
      media?.video;
    media?.[deviceType === 'mobile' ? 'videoMobile' : 'video'] || media?.video;

    if (video) {
      return (
        <>
          <video
            className="media-item video"
            poster={path.asset(app.environment, video?.poster)}
            ref={videoRef}
            autoPlay
            muted={true}
            controls={false}
            loop={true}
            playsInline
          >
            <source src={path.asset(app.environment, video?.src)} />
          </video>
          <button className="video-handle-btn" onClick={handleVideoToggle}>
            <Icon name={videoIsPlay ? 'icon-pause' : 'icon-play'} />
          </button>
        </>
      );
    } else if (media?.image) {
      return (
        <Image
          className="media-item image"
          {...(deviceType !== 'mobile'
            ? media?.image
            : media?.mobileImage || media?.image)}
        />
      );
    }

    return null;
  };

  if (!media) {
    return null;
  }

  return (
    <div className="single-slider-hero">
      <div className="single-media">
        {renderMedia()}
        <SocialIconList className="single-media-socials" />
        <div className="media-on-texts">
          {media?.title && <div className="title">{media?.title}</div>}
          {media?.desc && <div className="text">{media?.desc}</div>}
        </div>
        <div className="main-media-texts">
          {media?.mainTitle && <h1 className="title">{media?.mainTitle}</h1>}
          {media?.mainDesc && <p className="desc">{media?.mainDesc}</p>}
          {media?.titleIcon && (
            <Image className="title-icon" {...media?.titleIcon} />
          )}
        </div>
        {isEmergency && emergencyLink?.href && (
          <div className="emergency">
            <Anchor className="emergency-btn" {...emergencyLink}>
              <Icon name="icon-emergency" />
              <span className="text">
                {app.settings.translations['emergency']}
              </span>
            </Anchor>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleSliderHero;
