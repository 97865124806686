import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  ISliderHeroProps,
  ISliderMedia,
  ISliderItemTextOptions,
} from './types';
import { functions, path, useApp } from '@wap-client/core';
import Image from '@/components/base/image';
import useDeviceType from '@/hooks/useDeviceType';
import Icon from '@/components/base/icon';
import { Column, Container, Row } from '@/components/base/gridview';
import SocialIconList from '@/components/widgets/social-icon-list';
import { motion } from 'framer-motion';
import SingleSliderHero from '../single-slider-hero/SingleSliderHero';
import Anchor from '@/components/base/anchor';

const animations = {
  container: {
    hidden: {
      opacity: 0,
      scale: 0.6,
    },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.75,
        delayChildren: 0.3,
        staggerChildren: 0.15,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
  sliderContent: {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.3,
        delay: 0.3,
        delayChildren: 0.25,
        staggerChildren: 0.25,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
  sliderContentItem: {
    hidden: {
      opacity: 0,
      x: -100,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const Hero: React.FC<ISliderHeroProps> = ({
  sliderMedia = [],
  isEmergency = false,
  emergencyLink,
}) => {
  // const sliderMainRef = useRef<any>(null);
  // const sliderMobileTextsRef = useRef<any>(null);
  const [nav1, setNav1] = useState<any>(null);
  const [nav2, setNav2] = useState<any>(null);

  const app = useApp();
  const deviceType = useDeviceType();
  const [videoIsPlay, setVideoIsPlay] = useState<boolean>(true);

  const container = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    cssEase: 'ease-in-out',
  };

  const settings2 = {
    ...settings,
    dots: false,
    swipe: false,
    touchMove: false,
  };

  const handleVideoToggle = () => {
    setVideoIsPlay((prev) => !prev);
  };

  const handlePlayCurrentVideo: () => void = () => {
    const sliderHeroSocials = document.querySelector('.hero .social-icon-list');

    const slides = document.querySelectorAll('.slick-slide');
    let videoExistsInCurrentSlide = false;

    slides.forEach((slide) => {
      const video: HTMLVideoElement | null = slide.querySelector(
        '.slider-item-media-video'
      );

      if (slide.classList.contains('slick-current')) {
        if (video) {
          if (videoIsPlay) {
            video.play().catch((error) => {
              console.error('Video cannot be played:', error.message);
            });
          }
          videoExistsInCurrentSlide = true;
        }
      } else {
        if (video && videoIsPlay) {
          video.pause();
        }
      }
    });

    if (sliderHeroSocials) {
      if (videoExistsInCurrentSlide) {
        sliderHeroSocials.classList.add('video-slider');
      } else {
        sliderHeroSocials.classList.remove('video-slider');
      }
    }
  };

  useEffect(() => {
    if (videoIsPlay) {
      handlePlayCurrentVideo();
    } else {
      const sliderVideos = document.querySelectorAll<HTMLVideoElement>(
        '.slider-item-media-video'
      );

      if (sliderVideos && sliderVideos.length > 0) {
        sliderVideos.forEach((video: HTMLVideoElement) => {
          video.pause();
        });
      }
    }
  }, [videoIsPlay]);

  const renderSliderItemText = (
    media: ISliderMedia,
    options: ISliderItemTextOptions = {}
  ) => {
    if (!media?.title && !media?.desc) {
      return null;
    }

    if (options?.showMobile === false && deviceType === 'mobile') {
      return null;
    }

    return (
      <div className="slider-item-desc">
        {media?.title && (
          <div className="slider-item-desc-title">{media.title}</div>
        )}
        {media?.desc && (
          <div className="slider-item-desc-text">{media.desc}</div>
        )}
      </div>
    );
  };

  const renderSliderItem = (media: ISliderMedia, index?: number) => {
    if (media) {
      const video =
        media[deviceType === 'mobile' ? 'videoMobile' : 'video'] || media.video;

      return (
        <div className="slider-item" key={index}>
          {video || media.image ? (
            <div
              className={functions.classnames(
                'slider-item-media',
                video && 'video-slider'
              )}
            >
              {video?.src ? (
                <>
                  <video
                    className="slider-item-media-video"
                    poster={path.asset(app.environment, video?.poster)}
                    autoPlay={index === 0}
                    muted={true}
                    controls={false}
                    loop={true}
                    playsInline
                  >
                    <source src={path.asset(app.environment, video?.src)} />
                  </video>
                  <button
                    className="video-handle-btn"
                    onClick={handleVideoToggle}
                  >
                    <Icon name={videoIsPlay ? 'icon-pause' : 'icon-play'} />
                  </button>
                </>
              ) : media.image ? (
                <Image
                  className="slider-item-media-image"
                  {...(deviceType !== 'mobile'
                    ? media.image
                    : media.mobileImage || media.image)}
                  key={index}
                />
              ) : null}
            </div>
          ) : null}
          {renderSliderItemText(media, { showMobile: false })}
          <div className="main-media-texts">
            {media?.mainTitle && <h1 className="title">{media.mainTitle}</h1>}
            {media?.mainDesc && <p className="desc">{media.mainDesc}</p>}
            {media?.titleIcon && (
              <Image className="title-icon" {...media?.titleIcon} />
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderSliderItemMobile = (media: ISliderMedia, index?: number) => {
    if (!media?.title && !media?.desc) {
      return null;
    }

    return (
      <div className="slider-item" key={index}>
        {renderSliderItemText(media)}
      </div>
    );
  };

  return (
    <motion.section
      className="hero"
      ref={container}
      variants={animations.container}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false }}
    >
      <Container size="fluid">
        <Row>
          <Column xs={{ size: 12 }} className="slider-container">
            {sliderMedia?.length > 1 ? (
              <>
                <Slider
                  asNavFor={nav2}
                  ref={setNav1}
                  className="hero-slider-main"
                  {...settings}
                  afterChange={handlePlayCurrentVideo}
                >
                  {sliderMedia?.map((media, index) =>
                    renderSliderItem(media, index)
                  )}
                </Slider>
                <SocialIconList className="slider-item-media-socials" />
                {isEmergency && emergencyLink?.href && (
                  <div className="emergency">
                    <Anchor className="emergency-btn" {...emergencyLink}>
                      <Icon name="icon-emergency" />
                      <span className="text">
                        {app.settings.translations['emergency']}
                      </span>
                    </Anchor>
                  </div>
                )}
              </>
            ) : (
              <SingleSliderHero
                media={sliderMedia?.[0]}
                isEmergency={isEmergency}
                emergencyLink={emergencyLink}
              />
            )}
          </Column>
        </Row>
        {deviceType === 'mobile' &&
          sliderMedia[0]?.desc &&
          sliderMedia[0]?.title && (
            <Row data-id="asdas">
              <Column xs={{ size: 12 }}>
                {sliderMedia?.length > 1 ? (
                  <>
                    <Slider
                      asNavFor={nav1}
                      ref={setNav2}
                      className="hero-slider-text"
                      {...settings2}
                    >
                      {sliderMedia.map((media, index) =>
                        renderSliderItemMobile(media, index)
                      )}
                    </Slider>
                  </>
                ) : (
                  renderSliderItemMobile(sliderMedia?.[0])
                )}
              </Column>
            </Row>
          )}
      </Container>
    </motion.section>
  );
};

export default Hero;
