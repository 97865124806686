import React from 'react';
import { ISocialIconListProps } from './types';
import { functions, useApp } from '@wap-client/core';
import Anchor from '@/components/base/anchor/Anchor';
import Icon from '@/components/base/icon';
import { motion } from 'framer-motion';

const animations = {
  container: {
    show: {
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.15,
      },
    },
  },
  item: {
    hidden: {
      opacity: 0,
      scale: 0.9,
      x: -20,
    },
    show: {
      opacity: 1,
      scale: 1,
      x: 0,
      transition: {
        duration: 0.3,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const SocialIconList: React.FC<ISocialIconListProps> = ({ className }) => {
  const app = useApp();
  return (
    <motion.div
      className={functions.classnames('social-icon-list', className)}
      variants={animations.container}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false }}
    >
      <Anchor
        motion={{ variants: animations.item }}
        href={app.settings.socials['instagram']}
        className="list-item"
        target="_blank"
      >
        <Icon name="icon-instagram" />
      </Anchor>
      <Anchor
        motion={{ variants: animations.item }}
        href={app.settings.socials['youtube']}
        className="list-item"
        target="_blank"
      >
        <Icon name="icon-youtube-full" />
      </Anchor>
      <Anchor
        motion={{ variants: animations.item }}
        href={app.settings.socials['twitter']}
        className="list-item"
        target="_blank"
      >
        <Icon name="icon-twitter" />
      </Anchor>
      <Anchor
        motion={{ variants: animations.item }}
        href={app.settings.socials['facebook']}
        className="list-item"
        target="_blank"
      >
        <Icon name="icon-facebook-thin" />
      </Anchor>
      <Anchor
        motion={{ variants: animations.item }}
        href={app.settings.socials['pinterest']}
        className="list-item"
        target="_blank"
      >
        <Icon name="icon-tik-tok" />
      </Anchor>
    </motion.div>
  );
};

export default SocialIconList;
